@import '../../../../assets/styles/variables';

.smallLogo {
  margin-bottom: 30px;
  margin-top: 50px;

  svg {
    width: 24px;
  }
}

.largeLogo {
  position: absolute;
  top: -200px;
  right: 0;
  z-index: -1;

  svg {
    width: 280px;

    path {
      fill: $primary-yellow;
    }
  }
}

.wrapper {
  width: 500px;

  .title {
    font-family: "GTWalsheimMedium", sans-serif;
    font-size: 50px;
    line-height: 50px;
    letter-spacing: -2px;
    margin-bottom: 24px;
  }

  .subtitle {
    font-size: 17px;
    line-height: 30px;
  }

  .loginWrapper {
    display: flex;
    align-items: center;

    span {
      font-size: 15px;
    }

    .loginButton {
      text-decoration: underline;
      color: $black;
      font-size: 15px;

      &:hover {
        color: $dark_grey_2;
      }
    }
  }
}


@media only screen and (max-width: 768px) {
  .smallLogo {
    left: 20px;
    margin-top: 0;
  }

  .wrapper {
    width: 100%;
    margin-top: 70px;
  }
}