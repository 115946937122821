@import '../../assets/styles/variables';

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 380px;

  .image {
    svg {
      width: 160px;
      height: 160px;
    }
  }

  .imageLarge {
    margin-bottom: 15px;

    svg {
      width: 180px;
      height: 180px;
    }
  }

  .title {
    font-size: 23px;
    font-weight: 500;
    margin-bottom: 40px;
  }

  .text {
    text-align: center;
    color: $dark_grey_2;

    &:not(:last-child) {
      margin-bottom: 23px;
    }
  }

  .buttonsRow {
    display: flex;
    flex-direction: row;
    gap: 20px;

    & > button {
      padding: 14px 50px;

      &:first-child {
        background-color: $primary_grey;
      }
    }
  }

  .message {
    display: flex;
    align-items: center;
    line-height: 1.33;
    flex-direction: column;
    gap: 25px;

    .row {
      display: flex;
      flex-direction: row;
      gap: 0;
      align-items: center;
    }

    svg {
      margin-right: 9px;

      path {
        fill: $light_green;
      }
    }

    span {
      color: $light_green;
    }

    .closeButton {
      padding: 14px 40px;
      background: $light_grey_2;
    }
  }

  .loader {
    display: flex;
    align-items: center;
    margin-top: 20px;

    @keyframes spinner {
      from { transform: rotate(0deg);   }
      to   { transform: rotate(360deg); }
    }

    .spinner {
      animation: spinner 1s linear infinite;
      background: #58c2f0;
      border-radius: 50%;
      height: 18px;
      width: 18px;
      position: relative;
      margin-right: 13px;
      flex-shrink: 0;

      &::before,
      &::after {
        content: '';
        position: absolute;
      }

      &::before {
        border-radius: 50%;
        background: linear-gradient(0deg, hsla(0, 0%, 100%, 1) 50%, hsla(0, 0%, 100%, 0.9) 100%) 0 0 no-repeat, linear-gradient(90deg, hsla(0, 0%, 100%, 0.9) 0%, hsla(0, 0%, 100%, 0.6) 100%) 100% 0 no-repeat, linear-gradient(180deg, hsla(0, 0%, 100%, 0.6) 0%, hsla(0, 0%, 100%, 0.3) 100%) 100% 100% no-repeat, linear-gradient(360deg, hsla(0, 0%, 100%, 0.3) 0%, hsla(0, 0%, 100%, 0) 100%) 0 100% no-repeat;
        background-size: 50% 50%;
        top: -1px;
        bottom: -1px;
        left: -1px;
        right: -1px;
      }

      &::after {
        background: white;
        border-radius: 50%;
        top: 15%;
        bottom: 15%;
        left: 15%;
        right: 15%;
      }
    }

    span {
      color: #58c2f0;
    }
  }

  .buttons {
    display: flex;
    gap: 20px;
  }

  .continueLaterButton {
    margin-top: 16px;
  }

  .downloadButton {
    background-color: $black;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;

    &:hover {
      background-color: $dark_grey_2;
    }

    svg {
      path {
        fill: $white;
      }
    }
  }
}

.editArticleModalWrapper {
  display: flex;
  flex-direction: column;

  .editArticleModalHeader {
    width: 100%;
    display: flex;
    margin-top: 12px;

    .leftSide {
      display: flex;
      align-items: center;
      font-size: 23px;
      font-family: "GTWalsheimMedium", sans-serif;
      color: $dark_grey_6;
    }

    .rightSide {
      display: flex;
      gap: 10px;
      margin-left: auto;
    }
  }
}

.downloadSuccess {
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    width: 170px;
  }

  .title {
    text-align: center;
    font-size: 23px;
    margin-bottom: 20px;
  }

  .text {
    text-align: center;
    font-size: 15px;
  }
}

.alternativesModalWrapper {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

    .heading {
      .title {
        font-size: 30px;
        font-family: $SecondaryFont;
        margin-bottom: 8px;
      }

      .subtitle {
        font-size: 17px;
        line-height: 1.76;
        letter-spacing: -0.34px;
        color: $dark_grey_5;
      }
    }

    .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }
  }

  .radioGroupWrapper {
    max-height: 500px;
    overflow-y: scroll;
    margin-top: 24px;
  }
}

.greyButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary_grey;
  height: 30px;

  &:hover {
    background-color: $light_grey_4;
  }
}

.confirmButton {
  display: flex;
  font-size: 15px;
  height: 30px;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .modal {
    .title {
      text-align: center;
    }
  }
}