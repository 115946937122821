@import '../../../assets/styles/variables';

.authPageContainer {
  display: flex;
  flex-flow: column;
  min-height: 100vh;

  .widthContainer {
    margin: 0 auto;
    max-width: 1340px;
    width: 100%;
    position: relative;
    padding: 0 110px 100px 190px;
  }
}

@media only screen and (max-width: 768px) {
  .authPageContainer {
    width: 100%;

    .widthContainer {
      padding: 20px;
    }
  }
}