@import '../../../assets/styles/variables';

.scroller {
  max-height: 100vh;
  overflow-y: auto;
  width: 100%;

  .element {
    width: 100%;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}