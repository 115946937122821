@import '../../assets/styles/variables.scss';

.container {
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  background-color: $white;
  border-bottom: 1px solid #e7e7e7;
  border-top: 2px solid $primary_yellow;
  padding: 15px 60px;
  z-index: 2;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 10px;
  }
}