@import '../../../../assets/styles/variables';

.boxWrapper {
  width: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 177px;
  right: 110px;

  .box {
    width: 100%;
    border-radius: 8px;
    border: solid 1px $light_grey_6;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 40px 30px 40px;
    height: 340px;


    .image {
      position: absolute;
      margin: 0 auto;
      top: -45px;
      width: 90px;
      height: 90px;
      border-radius: 50%;
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 20px 20px -5px rgba(0, 0, 0, 0.1);


      img {
        height: 80px;
        width: auto;
        border-radius: 50%;
      }
    }

    .name {
      font-size: 17px;
      font-family: "GTWalsheimMedium", sans-serif;
      letter-spacing: -0.34px;
      margin-bottom: 6px;
    }

    .company {
      font-size: 14px;
      letter-spacing: -0.3px;
      color: $dark_grey_6;
      margin-bottom: 11px;
    }

    .description {
      font-size: 14px;
      line-height: 30px;
      letter-spacing: -0.3px;
      text-align: center;
    }
  }

  .arrowsWrapper {
    display: flex;
    gap: 10px;
    margin-top: 20px;

    .arrow {
      width: 40px;
      height: 40px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $light_grey_5;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 1140px) {
  .boxWrapper {
    max-width: 280px;
  }
}

@media only screen and (max-width: 1080px) {
  .boxWrapper {
    max-width: 210px;
  }
}