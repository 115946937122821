@import '../../../../assets/styles/variables';

.wrapper {
  background: $light_grey;
  padding: 15px 30px 30px 30px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &.margin {
    margin-top: 40px;
  }

  .row {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    .title {
      font-family: "GTWalsheimMedium", sans-serif;
      font-size: 17px;
      font-weight: 500;
      line-height: 1.76;
      letter-spacing: -0.34px;
      color: $black;
    }

    .label {
      font-size: 15px;
      line-height: 2.33;
      letter-spacing: -0.3px;
      margin-right: 9px;
    }

    .questionMark {
      background: $white;
      height: 20px;
      width: 20px;
      border-radius: 10px;
      border: solid 1px #dfdfdf;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      color: $dark_grey_2;
      font-family: "GTWalsheimMedium", sans-serif;
      cursor: pointer;
    }
  }

  .input {
    input {
      width: 100%;
      height: 50px;
      border-radius: 8px;
      border: solid 1px #dfdfdf;
      background-color: #fff;
      padding: 6px 16px;
      font-family: $MainFont, sans-serif;
      outline: none;
      margin-bottom: 8px;

      &::placeholder {
        color: $light_grey_3;
      }

      &:focus {
        border-color: $primary_yellow;
        box-shadow: inset 0 0 0 1px $primary_yellow;
      }
    }
  }

  .keyMessages {
    width: 100%;
    min-height: 50px;
    border-radius: 8px;
    border: solid 1px #dfdfdf;
    background-color: #fff;
    padding: 9px 16px;

    &.focus {
      border-color: $primary_yellow;
      box-shadow: inset 0 0 0 1px $primary_yellow;
    }

    .message {
      display: flex;
      align-items: center;
      line-height: 1.87;
      letter-spacing: -0.35px;

      .bullet {
        display: flex;
        height: 5px;
        width: 8px;
        margin-right: 5px;
        margin-bottom: 3px;
      }

      .iconRemove {
        display: flex;
        align-items: center;
        margin-left: 5px;
        flex-shrink: 0;
        margin-bottom: auto;
        margin-top: 6px;

        svg {
          width: 14px;
          height: 14px;
          cursor: pointer;

          path {
            stroke: $dark_grey_5;
          }
        }
      }
    }

    .transparentInput {
      width: 100%;
      background-color: transparent;
      font-family: $MainFont, sans-serif;
      border: none;
      line-height: 1.87;
      letter-spacing: -0.3px;
      padding: 0;
      resize: none;

      &::placeholder {
        color: $light_grey_3;
      }

      &:focus {
        outline: none;
      }
    }
  }
  .editButton {
    margin: 20px 0 0;
    background-color: #000;
    color: #fff;
  }
}

.keyMessagesModal {
  margin-top: 16px;
  width: 100%;
  position: relative;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;

    .heading {
      .title {
        font-size: 30px;
        font-family: $SecondaryFont;
        line-height: 1;
        letter-spacing: -0.88px;
        color: $black;
        margin-bottom: 8px;

      }
      .subtitle {
        font-size: 17px;
        line-height: 1.76;
        letter-spacing: -0.34px;
        color: $dark_grey_5;
      }
    }

    .actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .cancelButton {
        background-color: $primary_grey;
        padding: 16px 30px;

        &:hover {
          background-color: $light_grey_4;
        }
      }

      .saveButton {
        padding: 16px 30px;
      }
    }
  }
  .addButton {
    margin-top: 15px;
    padding: 10px 25px 10px 21px;
    border-radius: 6px;
    background-color: #f4f4f4;
  }
  .deleteButton {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
    svg {
      fill: #000;
      display: flex;
      width: 12px;
      height: 13px;
    }
  }
  span {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -0.3px;
    color: $black;
  }

  .messagesWrapper {
    height: 350px;
    overflow-y: scroll;
    margin-top: 24px;

    .messageWrapper {
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      justify-content: center;
      align-items: center;
      width: 100%;
      cursor: pointer;

      .checkbox {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        border-radius: 6px;
        border: solid 1px #dfdfdf;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          display: none;
          width: 7.5px;
          height: 5.5px;
        }
      }

      .message {
        height: 50px;
        padding: 0 16px;
        border-radius: 8px;
        border: solid 1px #dfdfdf;
        background-color: #fff;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex: 1;
      }

      &.isActive {
        .checkbox {
          background-color: #ffe403;
          svg {
            display: flex;
          }
        }
        .message {
          border: solid 2px #ffe403;
        }
      }

      &.isDeletable {
        &:hover {
          .deleteButton {
            display: flex;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .keyMessagesModal {
    .messagesWrapper {
      .messageWrapper {
        .message {
          height: auto;
          padding: 8px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .keyMessagesModal {
    .messagesWrapper {
      .messageWrapper {
        .message {
          height: auto;
          padding: 8px;
        }
      }
    }
  }
}
