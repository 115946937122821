@import '../../../../assets/styles/variables.scss';

.wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.grayBar {
  width: 100%;
  //height: 2px;
  position: relative;
  background-color: rgba(88, 194, 240, 0.3);
  border-radius: 8px;
}

.blueBar {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  background-color: $primary_blue;
  transition: width 0.25s ease-in-out;
  border-radius: 8px;
}