@import '../../../../assets/styles/variables.scss';

.pageLoaderWrapper {
  background-color: $secondary_blue;
  border-radius: 8px;
  padding: 40px 89px 45px 89px;
  height: fit-content;
  margin: 0 auto;

  .pageLoaderContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 11px;

    .pageLoaderMainText {
      font-size: 19px;
      line-height: 1.47;
      letter-spacing: -0.3px;
      color: $primary_blue;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    .pageLoaderSecondaryText {
      display: flex;
      align-items: center;
      column-gap: 12px;

      svg {
        flex-shrink: 0;
      }

      span {
        line-height: 1.47;
        letter-spacing: -0.3px;
        color: $primary_blue;
        font-size: 15px;
      }
    }
  }
}

.componentLoaderWrapper {
  display: flex;
  column-gap: 13.5px;
  align-items: center;

  span {
    font-size: 15px;
    line-height: 2;
    letter-spacing: -0.3px;
    color: $primary_blue;
  }
}

@media only screen and (max-width: 768px) {
  .pageLoaderWrapper {
    padding: 40px 20px;
  }
}