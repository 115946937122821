@import '../../../../assets/styles/variables';

.input-container {
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 50px;
  min-width: 340px;

  padding: 4px 16px;
  border: 1px solid $light_grey_4;
  border-radius: 8px;
  position: relative;

  font-family: $MainFont;
  font-size: 15px;

  &:focus {
    outline: none;
  }

  &.invalid {
    border-color: $error_red;
  }
}

.errorText {
  display: flex;
  color: $error_red;
  margin-top: 8px;
}

.label {
  font-size: 15px;
  font-weight: 500;
  line-height: 2;
  letter-spacing: -0.3px;
  color: #000;
  margin-bottom: 6px;
}

.position-relative {
  position: relative;
  display: flex;
  align-items: center;

  input {
    width: 100%;
  }

  .position-absolute-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    right: 12px;
    top: 11px;

    height: 18px;
    width: 18px;
    border-radius: 50%;

    background-color: $primary_grey;

    &:hover {
      background-color: $primary_yellow;
    }
  }
}


@media only screen and (max-width: 768px) {
  .input-container {
    min-width: 200px;
  }
}