@import '../../../../assets/styles/variables.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 20px;

  .titleFinal{
    font-family: $MainFont;
    font-size: 15px;
    color: $light_green;
    display: flex;
    justify-content: center;
    align-items: center;

    .checkMark{
      min-height: 30px;
      min-width: 30px;
      border-radius: 15px;
      background: #e7f9e9;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
    }
  }

  .step {
    display: flex;
    justify-content: center;
    align-items: center;
    &.active .button {
      background-color: $primary_yellow;
      color: $black;
    }

    &.finished .button {
      background-color: $light_green;
      color: $black;
    }

    .button {
      width: 30px;
      height: 30px;
      margin: 0;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $light_grey_2;
      color: #c6c6c6;
      font-size: 13px;
      font-weight: 500;
    }

    .title {
      margin-left: 10px;
      font-size: 13px;
      font-weight: 500;
    }
  }
}

@media only screen and (max-width: 768px) {
  .container {
    gap: 6px;

    .step {
      title {
        margin-right: 6px;
      }
    }

    .titleFinal{
      font-size: 13px;
      max-width: 200px;

      .checkMark{
        min-height: 20px;
        min-width: 20px;
      }
    }

  }
}