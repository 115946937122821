@import '../../../assets/styles/variables';

.scroller {
  max-height: 100vh;
  overflow-y: auto;
  width: 100%;

  .element {
    width: 100%;
  }
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 70px 100px;
  gap: 30px;

  &.withPreview {
    width: 70%;
  }

  form {
    width: 100%;
  }

  .paragraphs {
    display: flex;
    flex-direction: column;

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background-color: #efefef;
      height: 40px;
      margin-right: auto;
      padding: 4px 30px 6px 31px;
      cursor: pointer;
    }
  }
}

.previewContainer {
  width: min(460px, 30%);
  padding: 70px 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 80px 10px;
  }

  .previewContainer {
    width: 100%;
  }
}