@import '../../../../assets/styles/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .leftSide {
      display: flex;
      font-family: "GTWalsheimMedium", sans-serif;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: -0.3px;
    }

    .rightSide {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      :first-child {
        color: $dark_grey_5;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: -0.3px;
      }

      .count {
        color: $black;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: -0.3px;
      }
    }
  }

  .blueInformationBox {
    display: flex;
    flex-direction: row;
    background-color: #e6f7fd;
    border-radius: 8px;
    padding: 11px 20px;
    gap: 8px;
    margin-top: 14px;

    .infoIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $primary_blue;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      color: $light_grey;
      font-weight: 700;
      font-size: 12px;
      flex-shrink: 0;
    }

    :last-child {
      color: $primary_blue;
    }
  }

  .separator {
    height: 1px;
    width: 100%;
    background-color: $light_grey_2;
    margin: 20px 0;
  }

  .outlinesWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 680px;
    overflow-y: scroll;

    .outlineItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .number {
        color: $dark_grey_5;
        opacity: 0;
        width: 12px;

        &.active {
          opacity: 1;
        }
      }

      .checkbox {
        height: 20px;
        width: 20px;
        border: 1px solid $light_grey_3;
        border-radius: 6px;
        cursor: pointer;
        flex-shrink: 0;

        &.active {
          background-color: $primary_yellow;
          background-image: url("../../../../../src/assets/svg/check.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 9px;
        }
      }

      input {
        outline: none;
        padding: 1px 17px;
        border-radius: 8px;
        border: solid 1px $light_grey_4;
        height: 50px;
        width: 100%;

        &.active {
          border: 2px solid $primary_yellow;
          padding: 0 16px;
        }
      }

      &:hover {
        .dragIcon {
          opacity: 1;
        }
      }

      .dragIcon {
        display: flex;
        opacity: 0;
        cursor: grab;

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .button {
    background-color: $light_grey_2;
    color: $black;
    margin-top: 30px;
    margin-left: 22px;
  }
}
