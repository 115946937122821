@import '../../../../assets/styles/variables';

.tableContainer {
    display: flex;
    flex-direction: column;
    width: 100%;

    .table {
        width: 100%;
        border-collapse: collapse;

        .header {
            color: $dark_grey_5;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            text-align: left;
            height: 47px;
            border-top: 1px solid $light_grey_2;
        }

        .row {
            height: 70px;
            border-top: 1px solid $light_grey_2;
            border-bottom: 1px solid $light_grey_2;
        }

        tbody > tr > td {
            position: relative;
        }
    }

    .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 30px;

        .icon {
            display: flex;
            align-items: center;

            &:first-child {
                margin-right: 20px;
            }

            &:last-child {
                margin-left: 20px;
            }

            svg {
                height: 16px;
                width: 10px;
                cursor: pointer;
            }
        }

        .numerous {
            display: flex;
            gap: 10px;

            .number {
                display: flex;
                align-items: center;
                border-radius: 8px;
                cursor: pointer;
                font-size: 15px;
                height: 40px;
                justify-content: center;
                overflow: hidden;
                width: 40px;
                background-color: $light_grey;
                color: $dark_grey_5;

                &.active {
                    background-color: $white;
                }
            }

            .dots {
                display: flex;
                justify-content: center;
                align-items: center;
                color: $dark_grey_5;
            }
        }
    }
}

