@import '../../../../assets/styles/variables';

.wrapper {
  width: 100%;
  min-width: 340px;
  position: relative;

  .label {
    margin-bottom: 6px;
    font-family: "GTWalsheimMedium", sans-serif;
    line-height: 30px;
  }

  .inputContainer {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: solid 2px $light_grey_3;
    background-color: $white;
    padding: 4px 16px;
    font-family: $MainFont, sans-serif;
    color: $black;
    position: relative;
    display: flex;

    &::placeholder {
      color: $light_grey_3;
    }

    &.focus:focus {
      border-color: $primary_yellow;
      box-shadow: inset 0 0 0 1px $primary_yellow;
    }

    &.errorBorder {
      border: solid 1px $error_red;
    }
  }

  .message {
    margin-top: 8px;
    font-size: 13px;
    line-height: 30px;
    color: $dark_grey_2;
  }

  .error {
    margin-top: 8px;
    font-size: 13px;
    line-height: 30px;
    color: $error_red;

    span {
      color: $error_red;
    }
  }
  .checkButton{
    width: 30px;
    height: 30px;
    border-radius: 6px;
    border: none;
    background-color: $primary_yellow;
    position: absolute;
    right: 8px;
    top: 5px;
    padding: 0;
    cursor: pointer;

    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }
}