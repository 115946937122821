@import '../../../../assets/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 780px;
  justify-content: center;

  .element {
    width: 100%;
  }

  .title {
    display: flex;
    align-items: center;
    gap: 10px;
    line-height: 2;
    letter-spacing: -0.3px;
    font-family: $SecondaryFont;
    margin-top: 18px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 11px;

    .radioButtonWrapper {
      .radioButtonHeader {
        display: flex;
        gap: 14px;
      }

      .radioButtonContent {
        margin-left: 34px;
        color: $dark_grey_5;
        font-size: 13px;
        line-height: 1.54;
        letter-spacing: -0.26px;

        .radioButtonCaption {
          color: $light_green;
          font-size: 13px;
          margin-top: 5px;
        }
      }
    }
  }

  .completed {
    .fieldTitle {
      font-family: $MainFont;
      color: $dark_grey_5;
      font-size: 13px;
      line-height: 3.08;
      letter-spacing: -0.26px;
    }

    .value {
      font-size: 15px;
      display: flex;
      gap: 5px;

      .pill {
        color: $black;
        padding: 7px 15px;
        border-radius: 15px;
        background-color: $primary_grey;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .container {
    width: 380px;
  }
}