.logo  {
  svg {
    g {
      path {
        fill: hsl(0, 0%, 80%);
      }
      rect{
        animation: line-fill 3.5s ease forwards;
        opacity: 1;
        @keyframes line-fill{
          0%{
            transform: translate(0, 0);
            fill: hsl(0, 0%, 80%);
          }
          31% {
            transform: translate(0, 160px);
            fill: hsl(0, 0%, 80%);
          }
          100% {
            fill: #FFE403;
          }
        }

      }
    }
  }
}
