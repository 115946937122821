@import '../../../../assets/styles/variables.scss';

.questionMark {
  background: $white;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  border: solid 1px #dfdfdf;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  color: $dark_grey_2;
  font-family: "GTWalsheimMedium", sans-serif;
  cursor: pointer;
  z-index: 1;
  padding: 0;
}

@media only screen and (max-width: 768px) {
  .questionMark {
    position: relative;
    cursor: pointer;
  }

  .moreInfoMobile {
    position: absolute;
    top: 240px;
    border-radius: 8px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px $light_grey_4;
    background-color: $white;
    padding: 16px;
    z-index: 1;
  }
}