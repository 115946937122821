@import '../../../../assets/styles/variables';

.wrapper {
  width: 100%;
  min-width: 340px;

  .label {
    color: $dark_grey_5;
    line-height: 20px;
    letter-spacing: -0.3px;
    margin-bottom: 10px;
  }

  .inputContainer {
    width: 100%;
    height: 50px;
    border-radius: 8px;
    border: solid 1px $light_grey_4;
    background-color: $white;
    padding: 4px 16px;
    font-family: $MainFont, sans-serif;
    color: $black;
    position: relative;
    display: flex;

    &::placeholder {
      color: $light_grey_3;
    }

    &:focus {
      border: solid 2px $light_grey_3;
    }

    &.errorBorder {
      border: solid 1px $error_red;
    }
  }

  .message {
    margin-top: 8px;
    font-size: 13px;
    line-height: 30px;
    color: $dark_grey_2;
  }

  .error {
    margin-top: 8px;
    font-size: 13px;
    line-height: 30px;
    color: $error_red;

    span {
      color: $error_red;
    }
  }
}