@import '../../../../assets/styles/variables.scss';

.modalOverlay {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(46, 46, 46, 0.8);
  z-index: 9;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;

  .wrapper {
    display: flex;
    flex-direction: column;

    .close {
      margin-left: auto;
      width: 50px;
      height: 50px;
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 0.3);
      display: flex;
      cursor: pointer;

      svg {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
        margin: auto;

        path {
          stroke: $white;
        }
      }
    }

    .modal {
      max-height: 740px;
      overflow-y: scroll;
      background: $white;
      border-radius: 8px;
      color: black;
      min-width: 500px;
      max-width: 1090px;
      padding: 40px 60px;
      margin-top: 20px;
    }
  }
}

@media only screen and (max-width: 1090px) {
  .modalOverlay {
    .wrapper {
      .modal {
        max-width: 780px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .modalOverlay {
    .wrapper {
      .modal {
        max-width: 300px;
      }
    }
  }
}