.wrapper {
  background-color: #f8f8f8;
  padding: 40px 60px;
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;

  .textPicker {
    display: flex;

    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.33;
    letter-spacing: -0.3px;
    margin-bottom: 36px;
    
    div {
      opacity: 0.6;
      border-bottom: 1px solid #e7e7e7;
      text-align: center;
      cursor: pointer;
      width: 100%;
    }

    .isActive {
      opacity: 1;
      border-bottom: 2px solid #ffe403;
    }
  }

  .content {
    h1 {
      font-size: 17px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: -0.22px;
      color: #000;
      margin: 0 0 20px 0;
    }
    h2 {
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.31;
      letter-spacing: -0.17px;
      margin: 0;
    }
    p {
      font-size: 11px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.82;
      letter-spacing: -0.22px;
      color: #6f6f6f;
      margin: 0 0 20px 0;

      &:nth-child(2) {
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.54;
        letter-spacing: -0.17px;
      }
    }

    .placeholder {
      color: #c6c6c6;
    }
  }
}