@import '../../../../assets/styles/variables.scss';

.wrapper {
  position: relative;
  cursor: pointer;

  .button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 0 15px;
    border-radius: 8px;
  }

  .options {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 100%;
    margin-top: 10px;
    padding: 10px;
    background: $white;
    border-radius: 8px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px $light_grey_4;
    min-width: 220px;
    z-index: 1;
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;
  }
}