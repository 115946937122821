@import '../../../../assets/styles/variables';

.wrapper {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .label {
    font-size: 15px;
    font-weight: 500;
    line-height: 2;
    letter-spacing: -0.3px;
    color: $black;
    margin-bottom: 6px;
  }

  .select {
    display: flex;
    width: 270px;
    height: 50px;
    border-radius: 8px;
    border: solid 1px $light_grey_4;
    background-color: $white;
    padding: 14px 18.5px 16px 16px;
    font-family: $MainFont, sans-serif;
    align-items: center;
    cursor: pointer;

    &.outline {
      border: solid 2px $primary_yellow;
    }

    span {
      font-family: $MainFont;
      color: $black;

      &:last-child {
        margin-left: auto;
      }
    }

    img {
      margin-right: 12px;
    }
  }

  .dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 100%;
    margin-top: 10px;
    padding: 20px 10px 15px 10px;
    background: $white;
    border-radius: 8px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px $light_grey_4;
    width: 270px;
    max-height: 364px;
    animation: slideDown 300ms ease-in-out;
    z-index: 2;

    @keyframes slideDown {
      0% {
        opacity: 0;
        transform: translateY(-60px)
      }

      100% {
        opacity: 1;
        transform: translateY(0)
      }
    }

    .search {
      margin-bottom:9px;

      :focus {
        outline: none;
      }

      input {
        width: 100%;
        height: 50px;
        border-radius: 8px;
        border: solid 1px $light_grey_4;
        background-color: $white;
        padding: 0 16px;
        font-family: $MainFont, sans-serif;
        color: $black;

        &::placeholder {
          color: $light_grey_3;
        }

        &:focus {
          border-color: $primary_yellow;
          box-shadow: inset 0 0 0 1px $primary_yellow;
        }
      }
    }

    .options {
      overflow-x: hidden;
      overflow-y: auto;
      flex: 1;

      .option {
        display: flex;
        align-items: center;
        height: 40px;
        width: 100%;
        padding: 14px 11px;
        cursor: pointer;

        &:hover {
          border-radius: 8px;
          background-color: $light_grey;
        }

        &.selected {
          font-family: $SecondaryFont;
        }

        .icon {
          display: flex;
          align-items: center;
          margin-right: 12px;
          max-width: 18px;
          max-height: 18px;
        }
      }

      .noResults {
        color: $light_grey_3;
        height: 40px;
        padding: 14px 11px;
        width: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
}