@import '../../../../assets/styles/variables';

.card {
  padding: 45px 50px 50px 50px;
  background: #FFFFFF;
  border-radius: 8px;
  border: solid 1px #e7e7e7;
  display: flex;
  flex-direction: column;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.1);
  //margin: 0 auto;

  .row {
    display: flex;
    align-items: center;

    .checked {
      height: 30px;
      width: 30px;
      border-radius: 15px;
      background: #e7f9e9;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
    }

    .title {
      font-weight: 500;
      letter-spacing: -0.88px;
      font-size: 30px;
      margin-bottom: 8px;
    }

    .collapseButton {
      border-radius: 8px;
      background-color: #f4f4f4;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: auto;
      padding: 0 15px;
      width: 96px;
      cursor: pointer;
      margin-top: 5px;
      height: 30px;

      span {
        font-size: 13px;
      }
    }

    .editButton {
      background: $dark_grey_4;
      border-radius: 6px;
      padding: 6px 10px;
      margin-left: auto;
      opacity: 0;
      transition-duration: 0.2s;
    }
  }

  &.disabled {
    .title, .subtitle {
      color: #ababab;
    }
  }

  &.completed {
    .title {
      color: $black;
    }

    .subtitle {
      display: none;
    }
  }

  .subtitle {
    font-family: 'GTWalsheimRegular', sans-serif;
    font-weight: 400;
    font-size: 17px;
    line-height: 1.76;
    letter-spacing: -0.34px;
    color: #ababab;

    //&:not(:last-child) {
    //  margin-bottom: 22px;
    //}
  }

  .loader {
    display: flex;
    align-items: center;
    margin-top: 20px;

    @keyframes spinner {
      from { transform: rotate(0deg);   }
      to   { transform: rotate(360deg); }
    }

    .spinner {
      animation: spinner 1s linear infinite;
      background: #58c2f0;
      border-radius: 50%;
      height: 18px;
      width: 18px;
      position: relative;
      margin-right: 13px;
      flex-shrink: 0;

      &::before,
      &::after {
        content: '';
        position: absolute;
      }

      &::before {
        border-radius: 50%;
        background: linear-gradient(0deg, hsla(0, 0%, 100%, 1) 50%, hsla(0, 0%, 100%, 0.9) 100%) 0 0 no-repeat, linear-gradient(90deg, hsla(0, 0%, 100%, 0.9) 0%, hsla(0, 0%, 100%, 0.6) 100%) 100% 0 no-repeat, linear-gradient(180deg, hsla(0, 0%, 100%, 0.6) 0%, hsla(0, 0%, 100%, 0.3) 100%) 100% 100% no-repeat, linear-gradient(360deg, hsla(0, 0%, 100%, 0.3) 0%, hsla(0, 0%, 100%, 0) 100%) 0 100% no-repeat;
        background-size: 50% 50%;
        top: -1px;
        bottom: -1px;
        left: -1px;
        right: -1px;
      }

      &::after {
        background: white;
        border-radius: 50%;
        top: 15%;
        bottom: 15%;
        left: 15%;
        right: 15%;
      }
    }

    span {
      color: #58c2f0;
    }
  }

  .actionBlock {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 20px;
  }

  .content {
    font-size: 15px;
    letter-spacing: -0.3px;
    margin-top: 20px;
    margin-left: 45px;
    margin-right: 45px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: $dark_grey_5;
    word-break: break-word;

    span {
      color: $dark_grey_5;
    }
  }

  &:hover {
    .editButton {
      opacity: 1;
      transition-duration: 0.2s;
    }
  }
}

@media only screen and (max-width: 768px) {
  .card {
    padding: 20px;

    .row {
      .editButton {
        opacity: 1;
      }
    }
  }
}