@import '../../../../assets/styles/variables';

.smallLogo {
  position: absolute;
  left: 60px;
  top: 30px;
  display: flex;
  align-items: center;
  gap: 16px;

  span {
    font-family: "GTWalsheimMedium", sans-serif;
  }

  svg {
    width: 22px;
  }
}

.largeLogo {
  position: absolute;
  top: -360px;
  right: 140px;
  z-index: -1;

  svg {
    width: 280px;

    path {
      fill: $primary-yellow;
    }
  }
}

.wrapper {
  width: 500px;
  margin-top: 140px;

  .title {
    font-family: "GTWalsheimMedium", sans-serif;
    font-size: 35px;
    line-height: 50px;
    letter-spacing: -2px;
    margin-bottom: 24px;
  }

  .formWrapper {
    .form {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .subtitle {
        font-size: 17px;
        line-height: 30px;
        letter-spacing: -0.3px;
        color: $dark_grey_2;
      }
    }

    .checkboxWrap {
      display: flex;
      margin-top: 40px;
      gap: 14px;

      .checkbox {
        height: 24px;
        width: 24px;
        display: flex;
        flex-shrink: 0;
        border: 1px solid $light_grey_3;
        border-radius: 6px;
        cursor: pointer;

        &.active {
          background-color: $primary_yellow;
          background-image: url("../../../../assets/svg/check.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 9px;
        }
      }

      .sentence {
        font-size: 15px;
        line-height: 26px;
        margin-bottom: 27px;

        .link {
          text-decoration: underline;
          cursor: pointer;
          transition: color .3s;

          &:hover {
            color: $dark_grey_2;
          }
        }
      }
    }

    .error {
      color: #EB607B;
      height: 40px;
      display: flex;
      font-size: 15px;
    }

    .registerButton {
      padding: 20px 50px;
      font-family: "GTWalsheimMedium", sans-serif;
    }

    .loginWrapper {
      display: flex;
      align-items: center;
      margin-top: 20px;

      span {
        font-size: 15px;
      }

      .loginButton {
        text-decoration: underline;
        color: $black;
        font-size: 15px;

        &:hover {
          color: $dark_grey_2;
        }
      }
    }
  }
}


@media only screen and (max-width: 768px) {
  .smallLogo {
    left: 20px;
  }

  .wrapper {
    width: 100%;
    margin-top: 70px;
  }
}