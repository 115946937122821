@import '../../../assets/styles/variables.scss';

.element {
  width: 100%;
}

.finalContainer {
  margin: 60px auto;
  max-width: 1000px;

  .title {
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.88px;
  }

  .subtitle {
    font-size: 17px;
    line-height: 30px;
    letter-spacing: -0.34px;
    color: $dark_grey_5;
  }

  .containerRow {
    display: flex;
    gap: 50px;
    margin-bottom: 50px;

    .blueCard {
      background-color: #e6f6fd;
      border: none;

      .blueTitle {
        display: flex;
        flex-direction: column;
        gap: 30px;

        svg {
          width: 60px;
          height: 60px;
          fill: #58c2f0;
        }

        span {
          font-size: 30px;
        }
      }

      .blueContent {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .rowChecked {
          display: flex;
          gap: 10px;

          .greenCircle {
            background-color: #82e190;
            border-radius: 50%;
            min-width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              width: 8px;
              height: 7px;
            }
          }
        }
      }

      button {
        background: #58c2f0;
        color: white;
        font-size: 15px;
      }
    }

    .blackButton {
      font-size: 15px;
      background-color: $black;
      color: $white;
      flex-direction: row-reverse;

      &:disabled {
        background-color: $dark_grey_3;
        cursor: not-allowed;
        color: $white;
      }

      svg {
        width: 12px;
        height: 13px;

        path {
          fill: $white;
        }
      }
    }

    .downloadSuccess {
      display: flex;
      flex-direction: column;
      align-items: center;

      svg {
        width: 170px;
      }

      .title {
        text-align: center;
        font-size: 23px;
        margin-bottom: 15px;
      }

      .text {
        text-align: center;
        font-size: 15px;
      }
    }

    .buttonWrapper {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }

  .grayCard {
    padding: 55px 70px 0 70px;
    border-radius: 8px;
    background-color: #f4f4f4;

    .row {
      display: flex;
      flex-direction: row;
      gap: 90px;

      .image {
        img {
          height: 230px;
          width: 200px;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        gap: 24px;

        h2 {
          font-size: 30px;
          margin: 0;
          padding: 0;
          font-weight: 500;
          line-height: 1;
          letter-spacing: -0.88px;
        }

        p {
          font-size: 17px;
          line-height: 1.76;
          letter-spacing: -0.5px;
          margin: 0;
        }

        button {
          font-size: 15px;
        }
      }
    }

    .trustedBy {
      margin-top: 50px;
      height: 106px;
      border-top: 1px solid #dfdfdf;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 45px;
      flex-wrap: nowrap;

      span {
        font-size: 13px;
        line-height: 1.69;
        letter-spacing: -0.26px;
        color: #6f6f6f;
      }

    }
  }
}

@media only screen and (max-width: 768px) {
  .finalContainer {
    width: 100%;
    overflow: hidden;
    margin: 20px auto;

    .containerRow {
      display: flex;
      flex-direction: column;
      gap: 30px;
      margin-bottom: 30px;
    }

    .grayCard {
      display: block;
      padding: 20px;

      .row {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;

        .content {
          display: flex;
          align-items: center;

          p {
            text-align: center;
          }
        }
      }

      .trustedBy {
        gap: 0;
        flex-wrap: wrap;

        img {
          &:nth-child(5), &:nth-child(6), &:nth-child(7) {
            margin-top: -25px;
          }
        }
      }
    }
  }
}