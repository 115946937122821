.main {
    width: 100%;

    @media (min-width: 1300px) {
        padding: 0;
        margin: 0 auto;
    }
}

.overflow-hidden {
    overflow: hidden;
}