@import '../../../../assets/styles/variables';

.wrapper {
  width: 100%;
  margin-bottom: 45px;

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    .leftSide {
      span {
        color: $dark_grey_5;
        font-family: $SecondaryFont;
        line-height: 20px;
        letter-spacing: -0.3px;
      }
    }

    .rightSide {
      display: flex;
      gap: 20px;
      align-items: center;

      .icon {
        cursor: pointer;

        &.disabled {
          cursor: not-allowed;

          path {
            fill: $secondary_dark_grey;
          }
        }
      }
    }
  }

  .text {
    border: 1px solid $light_grey_4;
    border-radius: 8px;
    padding: 15px 20px;
    margin-top: 20px;
  }

  .footerActions {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 0 15px;
      height: 30px;
      background-color: $primary_grey;
      border-radius: 8px;
      cursor: pointer;

      span {
        font-size: 13px;
        font-family: $SecondaryFont;
      }
    }
  }
}