@import '../../../../assets/styles/variables';

.outlineWrapper{
  width: auto;
  border-radius: 8px;
  min-height: 40px;
  padding: 7px 20px 0px 20px;
  display: flex;
  align-items: center;
  position: relative;
  .tag{
    font-family: $MainFont;
    font-size: 11px;
    color: $dark_grey_5;
    margin-right: 16px;

    &.inputWrapperActive {
      margin-right: 6px;
    }
  }
  .title{
    font-family: $MainFont;
    font-size: 23px;
    color: $black;
    max-width: calc(100% - 40px);
  }
  .editIcon{
    opacity: 0;
    visibility: hidden;
    position: absolute;
    right: 20px;
    border: none;
    background-color: transparent;
    transition: all 0.5s;
    cursor: pointer;
    svg{
      width: 13.2px;
      height: 13.2px;
      margin-top: 7px;
      path {
        fill: $light_grey_3;
      }
    }
  }
  &.H3 {
    margin-left: 40px;
    .tag {
      margin-left: 10px;
    }
    .title {
      font-size: 17px;
      line-height: 1.41;
      letter-spacing: -0.22px;
      font-weight: 600;
    }
  }
  &.H4 {
    margin-left: 80px;
    .tag {
      margin-left: 10px;
    }
    .title {
      font-size: 17px;
      font-weight: 500;
      line-height: 1.41;
      letter-spacing: -0.22px;
    }
  }
  .inputWrapper{
    width: 100%;
  }
  .toolTipWrapper{
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.7s;
  }

  &.inputWrapperActive {
    padding: 0 5px 0 10px;
  }
}

.outlineWrapper:hover{
  background-color: $light_grey;
  .editIcon{
    opacity: 1;
    visibility: visible;
  }
  .toolTipWrapper{
    opacity: 1;
    visibility: visible;
  }
}

@media only screen and (max-width: 768px) {

}