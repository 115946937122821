@import '../../../../assets/styles/variables';

.wrapper {
  background-color: $white;
  border-radius: 6px;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  color: $black;
  font-size: 13px;
  line-height: 1.69;
  letter-spacing: -0.3px;
  opacity: 1;
  max-width: 200px;
  z-index: 99;

  &.hidden {
    display: none;
  }
}