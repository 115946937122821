@import '../../../../assets/styles/variables';

.action-button {
    padding: 17px 30px;
    background-color: $primary_yellow;
    opacity: 1;
    border: none;
    outline: none;
    max-width: max-content;
    transition: 0.5s;
    font-size: 15px;
    letter-spacing: -0.3px;
    text-align: center;
    color: $black;
    font-weight: 500;
    border-radius: 8px;
    display: flex;
    gap: 9px;
    cursor: pointer;

    &:hover {
        background-color: $secondary_light_yellow;
    }

    &:disabled {
        opacity: 0.3;
        pointer-events: none;
    }

}
.actionButton__arrow {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.exitButton {
    padding: 15px 20px;
    border-radius: 8px;
    background-color: $light_grey_2;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    gap: 10px;
    font-family: 'GTWalsheimMedium', sans-serif;

    svg {
        height: 10px;
        width: 10px;
    }

    &:hover {
        background-color: $light_grey_4;
    }
}

.secondaryButton {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.3px;
    background: transparent;
    border: none;
    color: #ababab;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
}

.nextButton {
    display: flex;
    align-items: center;

    svg {
        width: 12px;
        height: 12px;
        margin: auto;
    }
}

.previousButton {
    background-color: $primary_grey;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    svg {
        width: 12px;
        height: 12px;
        margin: auto;
    }
}

.footerButton {
    display: flex;
    align-items: center;
    background: $primary_yellow;
    color: $black;
    gap: 9px;

    .number {
        width: 30px;
        height: 30px;
        border-radius: 15px;
        background: $white;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.action-button {
        min-height: 60px;
        padding: 20px 40px;
    }
}

.optionButton {
    display: flex;
    align-items: center;
    gap: 14px;
    padding: 15px 18px;
    border-radius: 8px;
    border: 1px solid $light_grey_4;
    cursor: pointer;
    flex-shrink: 0;

    &.focus {
        outline: 2px solid $primary_yellow;
        outline-offset: -1px;
    }
}

@media only screen and (max-width: 768px) {
    .action-button {
        padding: 14px;
    }

    .footerButton {
        gap: 8px;
        z-index: 8;
    }
}

