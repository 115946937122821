@import '../../../../assets/styles/variables.scss';

.card {
  position: relative;
  padding: 40px 40px 37px 40px;
  background: $white;
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px $dark_grey_4;

  .toolCardHeader {
    display: flex;
    column-gap: 15px;
    margin-bottom: 25px;

    .icon {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }

    .titleContainer {
      .label {
        font-size: 11px;
        font-family: $SecondaryFont;
        line-height: 2;
        letter-spacing: -0.22px;
      }

      .title {
        font-size: 20px;
        font-family: $SecondaryFont;
        line-height: 1.3;
        letter-spacing: -0.74px;
      }
    }
  }

  .description {
    font-size: 14px;
    line-height: 1.73;
    letter-spacing: -0.32px;
    color: $dark_grey_2;
    margin-bottom: 21px;
    margin-top: auto;
  }

  .listWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 30px;

    .listItem {
      display: flex;
      column-gap: 10px;

      .checkMarkIcon {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #e7f9e9;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 8px;
          height: 6px;
        }
      }

      .listText {
        font-size: 14px;
        line-height: 1.47;
        letter-spacing: -0.3px;
      }
    }
  }

  &.disabled {
    .actionButton {
      background-color: $light_grey;
      opacity: 1;
      cursor: not-allowed;
      color: $light_grey_3;
    }
  }

  .actionButton {
    margin-top: auto;
    margin-bottom: 16px;
    min-width: 100%;
    align-items: center;
    justify-content: center;
  }

  .link {
    display: flex;
    column-gap: 8px;
    align-items: center;
    margin: 0 auto;
    cursor: pointer;

    .linkText {
      font-size: 15px;
      line-height: 1.47;
      letter-spacing: -0.3px;
      color: $dark_grey_5;
    }
  }
}