@import '../../../../assets/styles/variables';

.toolTipWrapper{
  border-radius: 6px;
  background-color: $white;
  position: absolute;
  width: fit-content;
  right: -5px;
  top: -30px;
  height: 40px;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  .firstTagButton {
    width: 30px;
    height: 30px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border: none;
    margin-left: 5px;
    margin-right: 1px;
    font-size: 11px;
    line-height: 2;
    letter-spacing: -0.25px;
    font-family: $SecondaryFont;
    cursor: pointer;
    &.active {
      background-color: $light_blue;
    }
    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
  .secondTagButton {
    width: 30px;
    height: 30px;
    border: none;
    margin-left: 1px;
    margin-right: 1px;
    font-size: 11px;
    line-height: 2;
    letter-spacing: -0.25px;
    font-family: $SecondaryFont;
    cursor: pointer;
    &.active {
      background-color: $light_blue;
    }
    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
  .thirdTagButton {
    width: 30px;
    height: 30px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border: none;
    margin-left: 1px;
    font-size: 11px;
    line-height: 2;
    letter-spacing: -0.25px;
    font-family: $SecondaryFont;
    cursor: pointer;
    &.active {
      background-color: $light_blue;
    }
    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
  .upButton{
    width: 30px;
    height: 30px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background-color: $light_grey_2;
    border: none;
    margin-left: 5px;
    margin-right: 1px;
    cursor: pointer;
    padding: 0;
    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
  .downButton{
    width: 30px;
    height: 30px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: $light_grey_2;
    border: none;
    cursor: pointer;
    padding: 0;
    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
  .deleteButton{
    width: 30px;
    height: 30px;
    border-radius: 6px;
    background-color: $light_grey_2;
    border: none;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
    padding: 0;
  }
}

@media only screen and (max-width: 768px) {
  .toolTipWrapper {

  }
}