@import '../../../../assets/styles/variables';

.wrapper {
  background: #f4f4f4;
  padding: 15px;
  border-radius: 8px;
  margin-top: 12px;
  position: relative;

  &.border {
    border: 1px solid $light_grey_3;
    border-radius: 9px;
  }

  &.noWrapper {
    background: $white;
    padding: 0;

    .row {
      display: none;
    }
  }

  &.toolbarHidden {
    > div:last-child > div:last-child {
      background: transparent;
      border: 0;
    }
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 11px;

    &:last-child {
      margin-bottom: 0;
    }

    .icon {
      cursor: pointer;
    }
  }

  .textEditorIcon {
    //padding: 12px;

    svg {
      width: 7px;
      height: 9px;
    }
  }
}