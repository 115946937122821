@import '../../../../assets/styles/variables';

.label {
  display: flex;
  align-items: center;
  gap: 10px;
  line-height: 2;
  letter-spacing: -0.3px;
  font-family: $SecondaryFont;
  margin-bottom: 11px;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}