@import '../../../../assets/styles/variables';

.element {
  width: 100%;
}

.title {
  display: flex;
  align-items: center;
  gap: 10px;
  line-height: 2;
  letter-spacing: -0.3px;
  font-family: $SecondaryFont;
  margin-top: 18px;
}

.error {
  color: $error_red;
}

.content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  .radioButtonWrapper {
    .radioButtonHeader {
      display: flex;
      gap: 14px;

      .icon {
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    .radioButtonContent {
      margin-left: 34px;
      color: $dark_grey_5;
      font-size: 13px;
      line-height: 1.54;
      letter-spacing: -0.26px;

      .radioButtonCaption {
        color: $light_green;
        font-size: 13px;
        margin-top: 5px;
      }
    }
  }
}

.completed {
  .fieldBlock {
    margin-bottom: 15px;
    .fieldTitle {
      font-family: $MainFont;
      color: $dark_grey_5;
      font-size: 13px;
      line-height: 3.08;
      letter-spacing: -0.26px;
    }

    .value {
      font-size: 15px;
      display: flex;
      gap: 5px;
      color: black;

      .pill {
        color: $black;
        padding: 7px 15px;
        border-radius: 15px;
        background-color: $primary_grey;
      }
    }
  }
}

.paragraphs {
  display: flex;
  flex-direction: column;

  .addParagraphButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #efefef;
    height: 40px;
    margin-right: auto;
    padding: 4px 30px 6px 31px;
    cursor: pointer;
  }
}

.regenerateParagraphButton {
  background-color: $light_grey_2;
  color: $black;
  padding: 6px 30px;
  line-height: 2;
}

.progressLoader {
  position: fixed;
  pointer-events: none;
  top: 35px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 150px;
    height: auto;
    opacity: 0.3;
    animation: fade 1.3s ease-in-out infinite;
  }

  @keyframes fade {
    0% {
      opacity: 0.3;
    }
    50% {
      opacity: 0.1;
    }
    100% {
      opacity: 0.3;
    }
  }
}

.mainTitle {
  font-size: 40px;
  font-family: $SecondaryFont;
  line-height: 0.95;
  letter-spacing: -0.52px;
  margin-top: 28px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;

  .h1Tag{
    font-size: 11px;
    color: $dark_grey_5;
    margin-right: 16px;
  }

  &.titleGenerate {
    margin-bottom: 5px;
  }
}

.caption {
  font-size: 11px;
  line-height: 1.82;
  letter-spacing: -0.22px;
  color: $dark_grey_5;
  margin-left: 20px;
}

.outlinesWrapper {
  margin: 20px 0;
}

.title {
  display: flex;
  align-items: center;
  gap: 10px;
  line-height: 2;
  letter-spacing: -0.3px;
  font-family: $SecondaryFont;
  margin-top: 18px;
}

.error {
  color: $error_red;
}

.content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 11px;

  .radioButtonWrapper {
    .radioButtonHeader {
      display: flex;
      gap: 14px;

      .icon {
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    .radioButtonContent {
      margin-left: 34px;
      color: $dark_grey_5;
      font-size: 13px;
      line-height: 1.54;
      letter-spacing: -0.26px;

      .radioButtonCaption {
        color: $light_green;
        font-size: 13px;
        margin-top: 5px;
      }
    }
  }
}

.completed {
  .fieldBlock {
    margin-bottom: 15px;
    .fieldTitle {
      font-family: $MainFont;
      color: $dark_grey_5;
      font-size: 13px;
      line-height: 3.08;
      letter-spacing: -0.26px;
    }

    .value {
      font-size: 15px;
      display: flex;
      gap: 5px;
      color: black;

      .pill {
        color: $black;
        padding: 7px 15px;
        border-radius: 15px;
        background-color: $primary_grey;
      }
    }
  }
}

.pageLoaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;

  .linkText {
    color: $dark_grey_2;

    .link {
      text-decoration: underline;
      cursor: pointer;
      color: $dark_grey_2;

      &:hover {
        color: $primary_dark;
      }
    }
  }
}

.loader {
  display: flex;
  align-items: center;
  margin-top: 20px;
  color: #58c2f0;
}

@media only screen and (max-width: 768px) {
  .mainTitle {
    font-size: 26px;
    margin-top: 10px;
    margin-left: 0;

    &.titleOutlines {
      margin-bottom: 16px;
    }

    &.titleGenerate {
      margin-bottom: 33px;
    }
  }

  .caption {
    font-size: 11px;
    margin-left: 0;
  }

  .outlinesWrapper {
    margin: 16px 0;
  }
}
