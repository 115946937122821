@import '../../../../assets/styles/variables';

.wrapper {
  padding: 10px 20px;
  position: relative;
  border-radius: 8px;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    display: block;
    opacity: 0.3;
    pointer-events: none;
    transition: background 1.2s linear;
    border-radius: 8px;
  }

  &:hover {
    background-color: $light_grey;

    .header {
      .icon {
        opacity: 1;
        cursor: pointer;
      }
    }

    .actions {
      display: flex;
    }
  }

  &.highlight {
    &:after {
      background: $primary_yellow;
      transition: none;
    }
  }

  .actions {
    display: none;
    align-items: center;
    gap: 5px;
    position: absolute;
    right: 0;
    top: -35px;
    background-color: $white;
    padding: 5px;
    border-radius: 6px;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2);
    z-index: 6;

    .option {
      background-color: $light_grey_2;
      border-radius: 8px;
      min-height: 30px;
      display: flex;
      align-items: center;
      padding: 0 15px;
      gap: 8px;
      cursor: pointer;

      .actionIcon {
        height: 14px;
        width: 14px;

        svg {
          height: 14px;
          width: 14px;
        }
      }

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &.operations {
        height: 30px;
        padding: 9px;
      }

      .operationIcon {
        svg {
          width: 12px;
          height: 12px;
        }
      }

      &:not(.disabled):hover {
        background-color: $light_grey_4;
      }
    }

    .deleteDropdown {
      position: absolute;
      background-color: $white;
      padding: 5px;
      border-radius: 6px;
      box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.2);
      z-index: 6;
      right: 0;
      top: 40px;
      width: 200px;

      .deleteDropdownOptions {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .deleteDropdownOption {
          display: flex;
          flex-direction: column;
          height: 60px;
          justify-content: center;
          padding-left: 8px;

          &:hover {
            background-color: $light_grey_2;
            border-radius: 6px;
            cursor: pointer;
          }

          .optionDescription {
            color: $dark_grey_5;
            font-size: 13px;
            line-height: 1.6;
          }
        }
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;

    .title {
      font-family: $SecondaryFont;

      &.h2 {
        font-size: 23px;
        line-height: 1.04;
        letter-spacing: -0.3px;
        margin-bottom: 11px;
      }

      &.h3 {
        font-size: 19px;
        line-height: 1.26;
        letter-spacing: -0.25px;
        margin-bottom: 10px;
      }

      &.h4 {
        font-size: 15px;
        line-height: 1.6;
        letter-spacing: -0.2px;
        margin-bottom: 8px;
      }
    }

    .icon {
      opacity: 0;
      margin-left: auto;

      svg {
        height: 13px;
        width: 13px;

        path {
          fill: $light_grey_3;
        }
      }
    }
  }

  .text {
    line-height: 1.6;
    letter-spacing: -0.2px;

    div {
      font-size: 15px;
    }
  }
}

.editorWrapper {
  padding: 20px 30px 30px;
  border-radius: 8px;
  background-color: $light_grey;

  .editorHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .editorCaption {
      color: $dark_grey_5;
      font-size: 11px;

      &.h2 {
        margin-bottom: 10px;
      }

      &.h3 {
        margin-bottom: 8px;
      }

      &.h4 {
        margin-bottom: 7px;
      }
    }

    .editorActions {
      display: flex;
      margin-left: auto;
      gap: 10px;

      .editorButton {
        font-size: 13px;
        line-height: 1.54;
        letter-spacing: -0.26px;
        padding: 5px 15px;
      }

      .cancelButton {
        background-color: $light_grey_4;

        &:hover {
          background-color: $dark_grey_5;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .wrapper:hover {
    .actions {
      flex-wrap: wrap;
      top: auto;
      bottom: 0;
    }
  }
}
