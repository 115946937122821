@import '../../../assets/styles/variables.scss';

.wrapper {
  padding: 30px 60px;

  .filtersContainer {
    display: flex;
    justify-content: space-between;

    .filtersGroup {
      display: flex;
      gap: 10px;

      .createNewButton {
        color: $primary_yellow;
      }
    }

    .filter {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      padding: 0 15px;
      height: 40px;
      border-radius: 8px;
      border: 1px solid $dark_grey_4;
      cursor: pointer;
      position: relative;

      &.square {
        padding: 0 12.5px;
      }

      .search {
        display: flex;
        justify-content: space-between;
        align-items: center;

        :focus {
          outline: none;
        }

        input {
          width: 100%;
          background-color: $white;
          border: none;
          font-family: $MainFont, sans-serif;
          color: $black;
        }

        &.active {
          margin-right: 0;
          max-width: 350px;
          min-width: 200px;
        }
      }
    }
  }

  .filtersLabels{
    display: flex;
    color: $dark_grey_5;
    padding: 20px 0;
  }

  .documentsTable {
    display: flex;
  }
}

.nameColumn {
  display: flex;

  .nameColumnWrapper {
    display: flex;
    gap: 4px;

    .draft {
      color: $orange;
    }

    .wip {
      color: $dark_grey_5;
    }
  }
}

.toolIcon {
  background-size: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 15px;
    width: 15px;
  }

  &.blueIcon {
    background-color: #DEF3FC;

    svg {
      path {
        fill: $primary_blue;
      }
    }
  }

  &.purpleIcon {
    background-color: #ECE5FA;

    svg {
      path {
        fill: $primary_purple;
      }
    }
  }
}

.type {
  color: $dark_grey_5;
  background-color: $light_grey;
  height: 20px;
  border-radius: 10px;
  padding: 4px 10px;
  font-size: 11px;
  line-height: 14px;
  font-weight: 500;
}

.statusColumn {
  .status {
    color: $dark_grey_5;
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: -0.3px;

    .finishedButton {
      width: 137px;
      height: 30px;
      border-radius: 8px;
    }
  }

  .progressBarWrapper {
    display: flex;
    position: absolute;
    bottom: 0;

    .progressBarItem {
      background-color: #dfdfdf;
      border-radius: 1px;
      display: block;
      height: 3px;
      margin-right: 5px;
      width: 10px;

      &.active {
        background-color: $light_green;
      }
    }
  }

  .statusContinueButton {
    background-color: $light_grey;
    padding: 4px 43px 6px 44px;
    border-radius: 8px;
    font-family: 'GTWalsheimMedium', sans-serif;
    font-size: 13px;
    line-height: 1.54;
    letter-spacing: -0.3px;
    cursor: pointer;
    transition: 0.5s;
    height: 30px;

    &:hover {
      background-color: $light_grey_4;
    }
  }
}

.downloadButton {
  opacity: 0;

  &.active {
    opacity: 1;
    background-color: $primary_grey;
    border-radius: 8px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      width: 12px;
    }
  }
}

.moreButton {
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: center;
  position: relative;

  &.disabled {
    pointer-events: none;

    svg {
      path {
        fill: $light_grey_3;
      }
    }
  }
}

.groupTitle {
  font-size: 11px;
  padding-left: 10px;
  color: $dark_grey_5;
}

.option {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 0 10px;
  cursor: pointer;
  gap: 10px;
  cursor: pointer;

  .checkbox {
    height: 24px;
    width: 24px;
    border: 1px solid $light_grey_3;
    border-radius: 6px;

    &.active {
      background-color: $primary_yellow;
      background-image: url("../../../../src/assets/svg/check.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 9px;
    }

    &.inProgress {
      display: none;
    }
  }

  &.delete {
    color: $primary_red;
  }

  &:hover {
    border-radius: 8px;
    background-color: $light_grey;
  }
}

.header{
  display: flex;
  height: 61px;
  background-color: #f4f4f4;
  padding: 20px 0px 0px 60px;
  font-family: $SecondaryFont;
  color: #f4f4f4;
  font-size: 15px;
  align-items: center;
  .active{
    background-color: white;
    color: black;
    margin-right: 15px;
    width: 92px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    border: none;
    position: relative;
  }
  .active::after {
    content: "";
    position: absolute;
    left: 50%; /* center the border */
    bottom: 5px; /* align it to the bottom */
    width: 50%; /* width of the border, make this whatever you like */
    transform: translateX(-50%); /* this will ensure it stays centered */
    border-bottom: 2px solid #ffe403; /* border style */
  }
  .option{
    color: black;
    margin-right: 15px;
    width: 92px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    background-color: transparent;
    opacity: 0.6;
  }
}

