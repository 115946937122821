@import '../../../assets/styles/variables.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0;

  .title {
    font-family: $MainFont;
    margin-bottom: 35px;
    font-size: 60px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -2.4px;
    color: $black;
    max-width: 610px;
    text-align: center;
  }

  .subtitle {
    font-family: $MainFont;
    font-size: 20px;
    letter-spacing: -0.8px;
    color: $black;
    max-width: 610px;
    text-align: center;
  }

  .toolbox {
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    gap: 40px;
    flex-wrap: wrap;
    margin: 140px 0 -20px;

    & > div {
      flex-grow: 1;
      width: 30%;
      margin-bottom: 20px;
    }
  } 
}


@media only screen and (max-width: 768px) {
  .container {
    padding: 10px 10px;

    .title {
      font-size: 48px;
      margin-bottom: 15px;
    }

    .toolbox {
      display: flex;
      flex-direction: column;
      margin: 60px 0 -20px;

      & > div {
        width: 100%;
        margin-bottom: 40px;
      }
    }
  }
}