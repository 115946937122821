@import '../../../../assets/styles/variables.scss';

.card {
  position: relative;
  padding: 50px 30px;
  background: $white;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);

  &.disabled {
    .icon {
      background: url('../../../../assets/svg/placeholder-avatar-gray.svg');
    }
    .actionButton {
      background: $light_grey;
    }
  }

  .icon {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url('../../../../assets/svg/placeholder-avatar-yellow.svg');
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .title {
    font-weight: 500;
    letter-spacing: -0.6px;
    font-size: 26px;
    margin-bottom: 20px;
    text-align: center;
  }

  .subtitle {
    font-size: 16px;
    line-height: 1.63;
    letter-spacing: -0.3px;
    text-align: center;
    margin-bottom: 20px;
  }

  .actionButton {
    margin-top: auto;
  }
}