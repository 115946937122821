@import '../../../../assets/styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 36px 30px 40px 40px;
  border-radius: 8px;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.1);
  border: 1px solid $dark_grey_4;
  background-color: $white;
  width: 300px;
  height: fit-content;

  .heading {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 21px;

    .icon {
      svg {
        width: 26px;
        height: 26px;
      }
    }

    .title {
      font-family: $SecondaryFont;
      font-size: 30px;
      line-height: 1;
      letter-spacing: -0.88px;
    }
  }

  .description {
    font-size: 17px;
    line-height: 1.41;
    letter-spacing: -0.34px;
    color: $dark_grey_5;
    margin-bottom: 18px;
  }

  .estimation {
    margin-bottom: 24px;

    span {
      font-size: 15px;
      line-height: 1.6;
      letter-spacing: -0.3px;
    }

    .count {
      font-size: 15px;
      line-height: 1.6;
      letter-spacing: -0.3px;
    }
  }

  .infoBox {
    display: flex;
    flex-direction: row;
    background-color: $secondary_blue;
    border-radius: 8px;
    padding: 11px 20px;
    gap: 8px;

    .infoIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $primary_blue;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      color: $light_grey;
      font-weight: 700;
      font-size: 12px;
      flex-shrink: 0;
      margin-top: 4px;
    }

    :last-child {
      color: $primary_blue;
      font-size: 15px;
      line-height: 1.47;
      letter-spacing: -0.3px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .wrapper {
    padding: 26px 20px 20px 20px;

    .heading {
      margin-bottom: 10px;

      .icon {
        svg {
          width: 20px;
          height: 20px;
        }
      }

      .title {
        font-size: 24px;
      }
    }

    .description {
      font-size: 15px;
      margin-bottom: 10px;
    }

    .estimation {
      margin-bottom: 10px;
    }
  }
}