@import '../../../../assets/styles/variables';

:focus {
  outline: none;
}
.invalidURL{
    font-size: 12px;
    color: $error_red;
    padding: 5px 0 0 2px;
}

.wrapper {
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .label {
    font-size: 15px;
    font-weight: 500;
    line-height: 2;
    letter-spacing: -0.3px;
    color: #000;
    margin-bottom: 6px;
  }

  .inputSelector {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 30px;

    .button {
      display: flex;
      //flex: 1;
      justify-content: flex-start;
      align-items: center;
      padding: 18px 16px;
      border-radius: 8px;
      border: 1px solid $light_grey_4;
      cursor: pointer;
      min-width: 213px;

      &.isActive {
        border: 2px solid $primary_yellow;
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      svg {
        width: 18px;
        height: 18px;
        margin-right: 13px;
      }
    }
  }

  .input {
    display: flex;
    width: 100%;
    flex-direction: column;

    input {
      width: 100%;
      height: 50px;
      border-radius: 8px;
      border: solid 1px #dfdfdf;
      background-color: #fff;
      padding: 0 16px;
      font-family: $MainFont, sans-serif;

      &:focus {
        border-color: $primary_yellow;
        box-shadow: inset 0 0 0 1px $primary_yellow;
      }
    }

    .upload {
      display: flex;
      align-items: center;
      border: solid 1px #dfdfdf;
      background-color: #fff;
      color: #2e2e2e;
      padding: 0 16px;
      height: 50px;
      border-radius: 8px;

      .addFile {
        display: flex;
        align-items: center;
        cursor: pointer;
        flex: 1;


        svg {
          width: 18px;
          margin-right: 13px;
        }

        path {
          fill: #58c2f0;
        }
      }

      .crossIcon {
        display: flex;
        width: 10px;
        height: 10px;
        cursor: pointer;
      }
    }

    .box {
      background: $light_grey;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 50px 46px;
      margin-top: 20px;

      p {
        color: $primary_blue;
        text-align: center;
        margin-bottom: 24px;
      }

      .darkButton {
        background: $black;
        color: $white;
        font-size: 15px;
      }

      .infoIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $primary_blue;
        height: 20px;
        width: 20px;
        border-radius: 10px;
        color: $light_grey;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .wrapper {
    .inputSelector {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
}