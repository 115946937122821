.oneColumnWrapper {
  flex-direction: row;
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  display: flex;

  & > div:first-child {
    width: 780px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
}

.twoColumnWrapper {
  flex-direction: row;
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  column-gap: 130px;
  display: flex;

  & > div:first-child {
    width: 300px;
    display: flex;
    flex-direction: column;
  }

  & > div:last-child {
    margin-right: 130px;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
  }
}

@media only screen and (max-width: 768px) {
  .oneColumnWrapper {
    padding-left: 10px;
    padding-right: 10px;
  }

  .twoColumnWrapper {
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    row-gap: 30px;

    & > div:last-child {
      margin-right: 0;
    }
  }
}
