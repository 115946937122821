@import '../../../../assets/styles/variables';

:focus {
  outline: none;
}

.wrapper {
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .label {
    color: $dark_grey_5;
    line-height: 20px;
    letter-spacing: -0.3px;
    margin-bottom: 10px;
  }

  .description {
    color: $dark_grey_5;
    font-size: 15px;
    margin-bottom: 9px;
  }

  .input {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    min-height: 50px;
    border-radius: 8px;
    border: solid 1px $light_grey_4;
    background-color: $white;
    padding: 10px 16px;
    font-family: $MainFont, sans-serif;
    align-items: center;

    .pill {
      display: flex;
      align-items: center;
      background: $light_yellow_2;
      border-radius: 15px;
      padding: 4px 15px;
      margin-right: 5px;
      margin-top: 2px;

      span {
        display: flex;
        align-items: center;

        svg {
          display: flex;
          width: 15px;
          height: 15px;
          cursor: pointer;
          margin-left: 6px;
          flex-shrink: 0;
        }
      }
    }

    input {
      border: none;
      flex: 1;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: $light_grey_3;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .wrapper {
    .input {
      .pill {
        svg {
          margin-top: 4px;
        }
      }
      .mobilePlusIcon {
        display: flex;
        width: 24px;
        height: 24px;
        align-items: center;
        justify-content: center;
        background-color: $primary_yellow;
        border-radius: 50%;
        margin-left: auto;
      }
    }
  }
}