@import '../../../assets/styles/variables';

.scroller {
  max-height: 100vh;
  overflow-y: auto;
  width: 100%;

  .element {
    width: 100%;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 130px 0 50px 0;
}

.finalContainer {
  display: flex;
  flex-direction: column;
  padding: 130px 0 50px 0;
  width: 70%;
}

@media only screen and (max-width: 768px) {
  .finalContainer {
    width: 100%;
  }
}
