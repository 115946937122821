@import '../../../../assets/styles/variables';


.mainWrapper{
  width: 100%;
  height: 15px;
  margin-top: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .addButton{
    width: 20px;
    height: 20px;
    border-radius: 6px;
    border: none;
    background-color: $light_grey_2;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
    z-index: 5;
    cursor: pointer;
    padding: 0;
  }
  .line{
    width: 100%;
    height: 2px;
    background-color: $light_grey_2;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
  }
  .tagsWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 26px;
    width: 90px;
    height: 142px;
    padding: 10px 10px 12px;
    border-radius: 8px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #e7e7e7;
    transition: opacity 0.3s;
    background-color: $white;
    z-index: 1;
    .tag {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 40px;
      cursor: pointer;
      span {
        margin-left: 10px;
      }
      &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
      &:hover:not(.disabled) {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background-color: #f4f4f4;
      }
    }
  }
}

.mainWrapper:hover{
  .addButton {
    opacity: 1;
    visibility: visible;
  }
  .line{
    opacity: 1;
    visibility: visible;
  }
}