@import '../../../assets/styles/variables.scss';

.container {
  display: flex;
  flex-direction: column;

  .toolCardContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 40px;
    max-width: 1200px;
    margin: 60px auto 0 auto;

    .blueIcon {
      svg {
        path {
          fill: $primary_blue;
        }
      }
    }

    .purpleIcon {
      svg {
        path {
          fill: $primary_purple;
        }
      }
    }

    .greenIcon {
      svg {
        path {
          fill: $light_green;
        }
      }
    }
  }

  .supportBoxWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $light_grey;
    min-height: 50px;
    column-gap: 28px;
    width: fit-content;
    margin: 50px auto 0 auto;
    padding: 10px 10px 10px 30px;
    border-radius: 8px;

    .supportText {
      font-size: 13px;
      font-family: $SecondaryFont;
      line-height: 1.54;
      letter-spacing: -0.26px;
    }

    .supportButton {
      background-color: $white;
      font-size: 13px;
      font-family: $SecondaryFont;
      line-height: 1.85;
      letter-spacing: -0.26px;
      padding: 2px 20px 4px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .container {
    .toolCardContainer {
      flex-direction: column;
    }
  }
}