@font-face {
  font-family: GTWalsheimRegular;
  src: url("assets/fonts/GTWalsheim/GTWalsheimRegular.otf");
  font-weight: normal;
}

@font-face {
  font-family: GTWalsheimMedium;
  src: url("assets/fonts/GTWalsheim/GTWalsheimMedium.otf");
  font-weight: normal;
}

* {
  font-family: "GTWalsheim", sans-serif;
  color: #2e2e2e;
  font-size: 14px;
  box-sizing: border-box;
}

body {
  margin: 0;
}

.App {
  display: flex;
  min-height: 100vh;
  flex-direction: row;
  /* icon-grey */
  // background: #f9f9f9 url("assets/images/icon-grey.png") no-repeat 40% 50%;
  // background-size: contain;
  color: #2e2e2e;
}

.align-items {
  align-items: center;
  display: flex;
}
.display-flex {
  display: flex;
}
.display-inline-flex {
  /*display: inline-flex;*/
}
.logo {
  width: 28px;
}
.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
a {
  text-decoration: none;
  color: initial;

}
a:hover {
  color: initial;
}
p {
  margin-bottom: 0px;
}

.rdw-editor-toolbar {
  margin-bottom: 0;
  border: 1px solid #C6C6C6;
  border-radius: 8px 8px 0 0;
  border-bottom: none;

  .rdw-block-dropdown {
    position: absolute;
    border: 1px solid #C6C6C6;
    border-radius: 8px;
    width: 150px;
    height: 30px;
    margin: 0 5px 0 0;
    padding: 4px 11px 6px 12px;
  }
}

.rdw-editor-main {
  border: 1px solid #C6C6C6;
  border-radius: 0 0 8px 8px;
  padding: 15px 20px;
  background: #FFFFFF;
}

.rdw-option-wrapper {
  border: 1px solid #C6C6C6;
  border-radius: 8px;
  width: 30px;
  height: 30px;
}

.rdw-list-wrapper {
  margin-right: auto;
  //margin-left: 150px;
}

.rdw-dropdown-optionwrapper {
  top: 10px;
  left: -13px;
  width: 150px;
  border-radius: 8px;
  padding: 10px;
}

.rdw-dropdownoption-active {
  border-radius: 8px;
}

.rdw-dropdownoption-default {
  padding: 6px 10px;
}


