@import '../../../../assets/styles/variables.scss';

.element {
  width: 100%;
}

.nextButton {
  padding: 16px 40px;
  font-size: 15px;
  margin-left: auto;
}

.previousButton {
  padding: 16px 40px;
  font-size: 15px;
  background-color: $light_grey_2;
}

.paragraphs {
  display: flex;
  flex-direction: column;

  .addParagraphButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #efefef;
    height: 40px;
    margin-right: auto;
    padding: 4px 30px 6px 31px;
    cursor: pointer;
  }
}

.regenerateParagraphButton {
  background-color: $light_grey_2;
  color: $black;
  padding: 6px 30px;
  line-height: 2;
}

.progressLoader {
  position: fixed;
  pointer-events: none;
  top: 35px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 150px;
    height: auto;
    opacity: 0.3;
    animation: fade 1.3s ease-in-out infinite;
  }

  @keyframes fade {
    0% {
      opacity: 0.3;
    }
    50% {
      opacity: 0.1;
    }
    100% {
      opacity: 0.3;
    }
  }
}