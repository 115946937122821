@import '../../../../assets/styles/variables';

.wrapper {
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  .label {
    font-size: 15px;
    font-weight: 500;
    line-height: 2;
    letter-spacing: -0.3px;
    color: #000;
    margin-bottom: 6px;
    margin-top: 22px;
  }

  .radioItem {
    display: flex;
    align-items: center;

    &.active {
      .radio {
        border: solid 8px $primary_yellow;
        background-color: $black;
      }

      .textarea {
        border-color: $primary_yellow;
        box-shadow: inset 0 0 0 1px $primary_yellow;
      }
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .radio {
      height: 20px;
      width: 20px;
      border: solid 1px $light_grey_4;
      border-radius: 50px;
      margin-right: 10px;
      display: flex;
      flex-shrink: 0;
    }

    .textarea {
      width: 100%;
      min-height: 50px;
      border-radius: 8px;
      border: solid 1px $light_grey_4;
      background-color: $white;
      padding: 12px 16px;
      font-family: $MainFont, sans-serif;
      resize: none;

      &:focus {
        border: 2px solid $light_grey_3;
      }

      &.active {
        border: 1px solid $primary_yellow;
      }
    }
  }
}