@import '../../../../assets/styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 780px;
  margin-left: 20px;
  padding: 35px 40px;
  background-color: $primary_grey;
  border-radius: 8px;
  margin-top: 70px;

  .heading {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 12px;

    .icon {
      display: flex;
      align-items: center;

      svg {
        width: 12px;
        height: 16px;
      }
    }

    .title {
      font-size: 23px;
      line-height: 0.87;
      letter-spacing: -0.46px;
      font-family: $SecondaryFont;
    }
  }

  .subheading {
    font-size: 15px;
    line-height: 1.33;
    letter-spacing: -0.3px;
    color: $dark_grey_5;
  }

  .topicsWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 26px;

    .topic {
      display: flex;
      background-color: $white;
      min-height: 56px;
      align-items: center;
      padding: 8px 8px 8px 20px;
      border-radius: 8px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .sentence {
        font-size: 17px;
        font-family: $SecondaryFont;
        line-height: 1.41;
        letter-spacing: -0.22px;
        color: $dark_grey_2;
        margin-right: 6px;
      }

      .rightSide {
        display: flex;
        gap: 20px;
        align-items: center;
        margin-left: auto;

        .bluePill {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 6px;
          background-color: #ebf7fd;
          border-radius: 10px;
          height: 20px;
          padding: 2px 11px 4px 13px;

          .icon {
            svg {
              width: 8px;
              height: auto;

              path {
                fill: $primary_blue;
              }
            }
          }

          :last-child {
            color: $primary_blue;
            font-size: 11px;
          }
        }

        .addButton {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          padding: 10px;
          border-radius: 8px;
          background-color: $primary_grey;
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .wrapper {
    margin-left: 0;
    margin-top: 20px;
    padding: 20px;

    .topicsWrapper {
      .topic {
        padding: 8px;

        .sentence {
          font-size: 14px;
        }

        .rightSide {
          gap: 10px;

          .addButton {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            padding: 10px;
            border-radius: 8px;
            background-color: $primary_grey;
            cursor: pointer;
          }
        }
      }
    }
  }
}