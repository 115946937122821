@import '../../../../assets/styles/variables.scss';

.wrapper {
  position: fixed;
  z-index: 3;
  right: 0;
  top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;

  .button {
    padding: 15px;
    border: 1px solid $light_grey_4;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    background: $white;
    transform: rotate(90deg);
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }
}

