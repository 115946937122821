@import '../../../../assets/styles/variables';

.smallLogo {
  margin-bottom: 30px;
  margin-top: 50px;

  svg {
    width: 24px;
  }
}

.largeLogo {
  position: absolute;
  top: -200px;
  right: 0;
  z-index: -1;

  svg {
    width: 280px;

    path {
      fill: $primary-yellow;
    }
  }
}

.wrapper {
  width: 500px;

  .title {
    font-family: "GTWalsheimMedium", sans-serif;
    font-size: 50px;
    line-height: 50px;
    letter-spacing: -2px;
    margin-bottom: 24px;
  }

  .subtitle {
    font-size: 17px;
    line-height: 30px;
  }

  .form {
    margin-top: 42px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .checkboxWrap {
      display: flex;
      align-items: center;
      gap: 14px;
      margin-top: 10px;

      .checkbox {
        height: 24px;
        width: 24px;
        border: 1px solid $light_grey_3;
        border-radius: 6px;
        cursor: pointer;

        &.active {
          background-color: $primary_yellow;
          background-image: url("../../../../assets/svg/check.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 9px;
        }
      }

      span {
        font-size: 15px;
        line-height: 40px;
      }
    }

    .error {
      color: #EB607B;
    }

    .resetButton {
      padding: 20px 50px;
      font-family: "GTWalsheimMedium", sans-serif;
    }

    .loginWrapper {
      display: flex;
      align-items: center;

      span {
        font-size: 15px;
      }

      .loginButton {
        text-decoration: underline;
        color: $black;
        font-size: 15px;

        &:hover {
          color: $dark_grey_2;
        }
      }
    }
  }
}


@media only screen and (max-width: 768px) {
  .smallLogo {
    left: 20px;
    margin-top: 0;
  }

  .wrapper {
    width: 100%;
    margin-top: 70px;
  }
}