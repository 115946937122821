@import '../../../../assets/styles/variables';

.largeLogo {
  position: absolute;
  top: -360px;
  right: 140px;
  z-index: -1;

  svg {
    width: 280px;

    path {
      fill: $primary-yellow;
    }
  }
}

.wrapper {
  width: 500px;
  margin-top: 80px;

  img {
    width: 100px;
    height: 100px;
    margin-bottom: 26px;
  }

  .title {
    font-size: 50px;
    line-height: 60px;
    font-family: "GTWalsheimMedium", sans-serif;
  }

  .text {
    font-size: 17px;
    line-height: 30px;
    letter-spacing: -0.3px;
    margin-bottom: 27px;
  }

  .contactWrapper {
    display: flex;
    gap: 20px;

    .contact {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $light_grey_2;
      width: 240px;
      height: 40px;
      border-radius: 8px;
      font-family: "GTWalsheimMedium", sans-serif;
      font-size: 15px;
      letter-spacing: -0.3px;
      cursor: pointer;
    }
  }

  .button {
    padding: 20px 50px;
    font-family: "GTWalsheimMedium", sans-serif;
  }
}

@media only screen and (max-width: 768px) {
  .wrapper {
    width: 100%;
  }

  .contactWrapper {
    display: flex;
    flex-direction: column;
  }
}