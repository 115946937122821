@import '../../../../assets/styles/variables.scss';

.mainWrapper{
  text-align: left;
  padding-left: 140px;
  .buttonWrapper{
    display: flex;

    .downloadButton{
      border: none;
      cursor: pointer;
      background-color: black;
      width: 168px;
      height: 60px;
      color: white;
      font-family: $MainFont;
      border-radius: 8px;
      margin-right: 20px;
    }

    .blackButton {
      font-size: 15px;
      background-color: $black;
      color: $white;
      flex-direction: row-reverse;
      padding: 0;
      width: 168px;
      height: 60px;
      max-width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: $dark_grey_2;
        transition: all 0.3s;
      }

      &:disabled {
        background-color: $dark_grey_3;
        cursor: not-allowed;
        color: $white;
      }

      svg {
        width: 12px;
        height: 13px;

        path {
          fill: $white;
        }
      }
    }

    .editButton{
      width: 113px;
      height: 60px;
      cursor: pointer;
      border: none;
      background-color: #f4f4f4;
      font-family: $MainFont;
      border-radius: 8px;
    }
  }
  .title{
    font-family: $MainFont;
    font-size: 40px;
    margin-bottom: 29px;
    font-weight: 600;
  }
  .introduction{
    font-family: $MainFont;
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: -0.2px;
  }
  .paragraph{
    margin: 40px 0 35px 0;
    .pTitle{
      font-family: $MainFont;
      margin-bottom: 11px;
      font-weight: 600;

      &.h2 {
        font-size: 23px;
        line-height: 1.04;
        letter-spacing: -0.3px;
      }

      &.h3 {
        font-size: 19px;
        line-height: 1.26;
        letter-spacing: -0.25px;
      }

      &.h4 {
        font-size: 15px;
        line-height: 1.6;
        letter-spacing: -0.2px;
      }
    }
    .content{
      font-family: $MainFont;
      font-size: 15px;
      line-height: 1.6;
      letter-spacing: -0.2px;
    }
  }
  .conclusion{
    line-height: 1.6;
    font-family: $MainFont;
    font-size: 15px;
    letter-spacing: -0.2px;
  }
}

@media only screen and (max-width: 768px) {
  .mainWrapper {
    padding: 10px;
  }
}