.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .titleContainer {
    display: flex;
    align-items: center;

    img {
      width: 22px;
      height: 40px;
      margin-right: 15px;
    }
    .title {
      font-size: 17px;
      font-weight: 500;
      letter-spacing: -0.3px;
    }
  }

  .buttons {
    display: flex;
    gap: 10px;
  }
}


@media only screen and (max-width: 768px) {
  .container {
    .titleContainer {
      display: flex;
      width: 30%;
    }

    .buttons {
      display: none;
    }
  }
}
