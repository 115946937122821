$primary_dark: #2E2E2E;
$primary_grey: #F2F2F2;
$primary_yellow: #FFE403;
$primary_blue: #58C2F0;
$primary_purple: #A17EE8;

$secondary_dark: #F2F2F2;
$secondary_light_yellow: #FFFECF;
$secondary_dark_grey: #B3B3B3;
$secondary_soft_yellow: #FFF59C;
$secondary_blue: #EEF8FD;

$white: #FFFFFF;
$dark_grey: #F9F9F9;
$dark_grey_2: #6F6F6F;
$dark_grey_3: #C3C3C3;
$dark_grey_4: #E7E7E7;
$dark_grey_5: #ABABAB;
$dark_grey_6: #9E9E9E;
$black: #000000;
$light_grey: #F4F4F4;
$light_grey_2: #EFEFEF;
$light_grey_3: #C6C6C6;
$light_grey_4: #DFDFDF;
$light_grey_5: #EAEAEA;
$light_grey_6: #EBEBEB;
$light_yellow_2: #FFFACD;
$light_blue: #D4EAF4;

$alert_green: #21C55D;
$light_green: #82E190;
$ultra_light_green: #E7f9E9;

$error_red: #EB607B;
$primary_red: #E77074;
$orange: #FF9751;

$MainFont: "GTWalsheimRegular", sans-serif;
$SecondaryFont: "GTWalsheimMedium", sans-serif;

$box_shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);