@import '../../../assets/styles/variables.scss';

.container {
  padding: 0 60px;
  background-color: $light-grey;
  width: 100%;

  .widthContainer {
    max-width: initial;
    margin-bottom: 29px;
    border-bottom: 1px solid #E5E5E5;
    display: flex;
    align-items: center;
    height: 70px;

    .navigation {
      margin: 0 auto;
      display: flex;

      .navigationItem {
        display: flex;
        padding: 0;
        margin: 0 25px;
        font-family: 'GTWalsheimMedium', sans-serif;
        font-size: 18px;
        height: 70px;
        align-items: center;
        cursor: pointer;
        border-bottom: 2px solid transparent;

        &.active {
          border-bottom: 2px solid $primary_yellow;
        }
      }
    }

    .rightSide {
      display: flex;
      margin-left: auto;

      .user {
        width: 40px;
        height: 40px;
        background-color: #eae6f3;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        span {
          color: #a17ee8;
          font-size: 15px;
          font-family: 'GTWalsheimMedium', sans-serif;
        }
      }

      .buttonGroup {
        display: flex;
        gap: 10px;

        .button {
          padding: 10px 30px;
        }
      }
    }
  }

  .logo {
    width: 300px;

    svg {
      width: 24px;
    }
  }

  .tabs {
    padding-bottom: 40px;

    .tabName {
      font-size: 30px;
      font-weight: 500;
      font-family: 'GTWalsheimMedium', sans-serif;
    }
  }
}

.option {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 0 10px;
  cursor: pointer;
  gap: 10px;

  &:hover {
    border-radius: 8px;
    background-color: $light_grey;
  }

  &.logout {
    color: $primary_red;
  }
}