@import '../../../assets/styles/variables';

.container {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;

	& > :last-child {
		margin-left: auto;
	}

	.previousButton {
		background-color: $primary_grey;
		min-height: 60px;
    padding: 20px 40px;

		&:hover {
			background-color: $light_grey_4;
		}
	}
}

@media only screen and (max-width: 768px) {
	.container {
		gap: 10px;
	}
}
