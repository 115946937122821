@import '../../../../assets/styles/variables';

.wrapper {
  position: relative;

  .button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 0 15px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid $dark_grey_4;
    cursor: pointer;
  }

  .options {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    margin-top: 10px;
    padding: 10px;
    background: $white;
    border-radius: 8px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px $light_grey_4;
    min-width: 200px;
    max-height: 200px;
    z-index: 1;
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;

    .option {
      line-height: 40px;
      padding: 0 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;

      .checkbox {
        height: 24px;
        width: 24px;
        border: 1px solid $light_grey_3;
        border-radius: 6px;

        &.active {
          background-color: $primary_yellow;
          background-image: url("../../../../../src/assets/svg/check.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 9px;
        }
      }

      &:hover {
        border-radius: 8px;
        background-color: $light_grey;
      }
    }
  }
}