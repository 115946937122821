@import '../../../assets/styles/variables.scss';

.footerContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 40px;
  width: 100%;
  height: 70px;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: $light_grey;
  padding: 0 40px;
  z-index: 2;

  .supportWrapper {
    display: flex;
    align-items: center;
    gap: 20px;

    span {
      font-size: 15px;
      line-height: 22px;
      color: $dark_grey_2;
      transition: color .3s;
      cursor: pointer;

      &:hover {
        color: $black;
      }
    }
  }

  .footerLogo {
    color: $black;
    letter-spacing: -1px;
    font-size: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .footerContainer {
    padding: 10px;
    z-index: 8;
  }
}