@import '../../assets/styles/variables.scss';

.container {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: $white;
  border-bottom: 1px solid #e7e7e7;
  border-top: 2px solid #e7e7e7;
  padding: 10px 60px;
  z-index: 2;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 10px;
    z-index: 8;
  }
}